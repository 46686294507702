<template>
  <moe-page title="菜单管理">
    <!-- 菜单信息 -->
    <moe-table ref="menuTable" url="role/menu/all" :params="menuParams" :number-show="false" rowKey="id" class="tab-left">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$router.push('/menu/add')">菜单添加</el-button>
      </template>

      <el-table-column label="标题" min-width="130">
        <template slot-scope="scope">
            {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="icon" min-width="130" />
      <el-table-column prop="path" label="地址" min-width="130" />
      <el-table-column prop="sort" label="排序" min-width="130" />
      <el-table-column label="操作" width="300" fixed="right" class="tab-center">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$router.push(`/menu/amend?id=${row.id}`)">修改</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete"  @click="menuDelete(row.id)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'MenuList',
  data() {
    //菜单筛选信息
    let menuParams = {};

    return {
      menuParams, //菜单筛选信息
    };
  },
  created() {
  },
  methods: {

    /**
     * 删除菜单
     **/
    menuDelete(id) {
      this.$moe_layer.confirm('是否要删除该菜单 ?', () => {
        // 调用删除账号API
        this.$moe_api.ROLE_API.menuDelete(id).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');

            //刷新账号表格
            this.$refs.menuTable.loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      });
    },
  },
};
</script>
<style>
.tab-left .moe-table__cont[data-v-fef07a2a] th,
.tab-left .moe-table__cont[data-v-fef07a2a] td{
  text-align: left;
}
.tab-left .moe-table__head .el-table__cell:last-child,
.tab-left .moe-table__cont[data-v-fef07a2a] td .moe-table__btns{
  text-align: center;
}
</style>